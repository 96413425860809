import React, { useEffect, useState } from "react";
import { Styles } from "./styles/dashboardNavbar";
import { Dropdown, Avatar,CustomProvider } from "rsuite";
import { Icon } from "@iconify/react";
import Select from "react-select";
import { auth, payment, subjectGrade } from "../puglins/Utils/models";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import notistack from "../puglins/notistack";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "./common/element/elements";
import moment from "moment";
import {
  getAuthUser,
  getAuthUserSuccess,
  getNotificationData,
  isLogin,
  setAccess,
} from "../actions/initialData";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "@rbnd/react-dark-mode";
import { Button, Modal } from "react-bootstrap";
import noNetworkAction from "../puglins/Utils/errorManage";
import MobileNavBar from "./MobileNavBar";
import 'rsuite/dist/rsuite.css';  // Import dark theme CSS

let dataObj = {
  level_id: "",
  subject_id: "",
};
function DashboardNavbar(props) {
  const history = useHistory();
  const selector = useSelector(({ intialDataReducer }) => intialDataReducer);
  const { notification, authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );

  const [grade, setGrade] = useState(selector?.grade);
  const [subjects, setSubjects] = useState(selector?.subject);
  const [searchData, setSearchData] = useState(dataObj);
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const { mode, setMode } = useDarkMode();
  const [isOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [mobileNavbar, setMobileNavBar] = useState("closed");
  const [rechargeAmt, setRechargeAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  //----Style for react select dropdown
  const customStyles = (isDarkMode) => ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode === "dark" ? "#333" : "white",
      color: isDarkMode === "dark" ? "white" : "black",
      border: state.isFocused
        ? `1px solid ${isDarkMode === "dark" ? "white" : "blue"}`
        : "1px solid #ccc",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode === "dark" ? "#333" : "white",
      color: isDarkMode === "dark" ? "white" : "black",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? isDarkMode === "dark"
          ? "#444"
          : "#eee"
        : isDarkMode === "dark"
        ? "#333"
        : "white",
      color: state.isSelected
        ? isDarkMode === "dark"
          ? "white"
          : "black"
        : isDarkMode === "dark"
        ? "white"
        : "black",
      "&:hover": {
        backgroundColor: isDarkMode === "dark" ? "#555" : colors?.orange,
        color: isDarkMode === "dark" ? "white" : "black",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? isDarkMode === "dark"
          ? "white"
          : "black"
        : "inherit",
    }),
  });
  // DropDown box style 
  const customStyles2 = (mode) => ({
    background: mode === 'dark' ? '#333' : '#fff',
    color: mode === 'dark' ? '#fff' : '#000',
    '&:hover': {
      background: mode === 'dark' ? 'red' : 'orange',
    },
    '.rs-dropdown-item': {
      '&:hover': {
        backgroundColor: mode === 'dark' ? 'green' : 'red',
      },
    },
  });
  const { socket } = useSelector(({ intialDataReducer }) => intialDataReducer);

  useEffect(() => {
    // getGrade();
  }, [mode]);

  //-----Logout api call
  const logout = () => {
    auth
      .logout()
      .then((res) => {
        history.push("/");
        dispatch(setAccess(false));
        dispatch(getAuthUserSuccess(null));
        dispatch(isLogin(false));
        socket.emit("disconnect");
        notistack.success(res.message);
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        error?.data?.error === "Unauthorized" && auth.destroyToken();
        console.log("error while logging out",error);
      });
  };
  //-----To set search inputs
  const handleGradeOption = (id, name) => {
    setSearchData((prevData) => ({
      ...prevData,
      [name]: id,
    }));
  };
  //---To make option for Grade dropdown
  const optionsGrade =
    grade?.length &&
    grade?.map((item) => ({
      label: language === "en" ? item?.grade_name : item?.grade_name_fr,
      value: item?._id,
    }));

  //---To make subject for Grade dropdown
  const optionSubject =
    subjects?.length &&
    subjects?.map((item) => ({
      label: language === "en" ? item?.subject_name : item?.subject_name_fr,
      value: item?._id,
    }));

  // -----To clear notification
  const clearNotification = () => {
    auth
      .cleartNotification()
      .then(() => dispatch(getNotificationData()))
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        console.log("Error while deleting notification");
      });
  };

  // manage notification for Read and navigation to page on click
  const manageNotification = (id) => {
    subjectGrade
      .manageNotification(id)
      .then((res) => {
        dispatch(getNotificationData(history));
        if (
          res?.data?.type === 1 ||
          res?.data?.type === 4 ||
          res?.data?.type === 6
        )
          history.push({pathname:"/dashboard",state:(res?.data?.type)});
        else if (res?.data?.type === 2) history.push("/favourites");
        else if (res?.data?.type === 3 || res?.data?.type === 5)
          history.push("/myclasses");
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        console.log("Error while reading notification");
      });
  };

  // For language change
  // Change language
  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    // setCookie("language", newLanguage);
    languageChangeMapping(newLanguage);
  };

  // Langauge change api mapping
  const languageChangeMapping = (lang) => {
    let sendData = {
      language: lang,
      id: authUserData?._id,
    };
    authUserData &&
      auth
        .setLanguage(sendData)
        .then((res) => dispatch(getAuthUser))
        .catch((error) => {
          error?.message === "Network Error" && noNetworkAction(history);
          console.log("Error while updating language", error);
        });
  };

  // Manage Notification Box
  const manageNotificationBox = () => setShowNotification(!showNotification);

  // Manage Recharge Amount
  const manageRecharge = (e) => {
    let { name, value } = e?.target;
    if (value.length <= 3) {
      value = value.replace(/\D/g, "");
      setRechargeAmt((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Recharge Wallet Api Caling and validation
  const rechargeWallet = () => {
    
    if(Number(rechargeAmt?.amount)>=10 && Number(rechargeAmt?.amount)<=200){
    setLoading(true);
    payment
      .walletRecharge(rechargeAmt)
      .then((res) => {
        window.location.replace(res?.redirection_link);
        setLoading(false);
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        notistack.error(this("rechargeError"));
      });}
      else notistack.warning('Amonut should be between 5-200')

  };

 
  return (
    <Styles>
      <div className="main ">
        <div className="d-flex  ">
          <div className="partOne d-flex flex-row container ">
            {/**-----Nav bar section which conatain searchbar/options/LOGO/Avatar */}
            <div className="col-lg-9 col-12  py-3 d-flex flex-column flex-lg-row justify-content-start align-items-lg-center ">
              <div className="logoSection ml-3 d-flex justify-content-between align-items-center ">
                {!authUserData?.first_time_online && (
                  <div className="position-relative ">
                    {" "}
                    {/* Mobile view side bar menu bar  */}
                    <MobileNavBar
                      open={mobileNavbar}
                      setMobileNavBar={setMobileNavBar}
                      props={props}
                      handleChangeLanguage={handleChangeLanguage}
                      mode={mode}
                      setMode={setMode}
                      currentLanguage={currentLanguage}
                      setModal={setModal}
                      setIsOpen={setIsOpen}
                    />
                    <Icon
                      icon="mingcute:menu-fill"
                      width={15}
                      color={colors?.darkblue}
                      className="d-lg-none"
                      onClick={() => setMobileNavBar("open")}
                    />
                  </div>
                )}
                <Link to="/">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Logoheader.webp"
                    }
                    className="logo-image"
                    alt="logo"
                    loading="lazy"
                    height={'100%'} width={'100%'}
                  />
                </Link>
                {/** ---- For small screen help/notification and avtar block */}
                <div className="p-1 d-lg-none d-flex align-items-center ">
                  {" "}
                  <div className="float-end w-100  d-flex align-items-center justify-content-around   ">
                    <div className="right-nav ">
                      <ul className="nav menu-nav">
                        <li className="nav-item dropdown active">
                          {notification?.totalCount > 0 && (
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {notification?.totalCount}
                            </span>
                          )}
                          <Icon
                            icon="zondicons:notification"
                            width={25}
                            color={mode === "dark" ? "white" : "black"}
                            onClick={manageNotificationBox}
                          />
                          {showNotification && (
                            <ul
                              className="dropdown list-unstyled mt-2"
                              style={
                                mode === "dark"
                                  ? { backgroundColor: "#000000",zIndex:9999 }
                                  : { backgroundColor: "#fff",zIndex:9999 }
                                  
                              }
                            >
                              <li className="nav-item noti-center">
                                <div className="d-flex justify-content-between align-items-center ">
                                  <h6 className="p-3 ">Notification</h6>
                                  <span
                                    className="px-2 text-danger fw-bold "
                                    onClick={() => {
                                      clearNotification();
                                      manageNotificationBox();
                                    }}
                                  >
                                    Clear all{" "}
                                  </span>
                                </div>
                                {notification?.data?.map((msg, index) => {
                                  return (
                                    <div
                                      className=" my-2 rounded shadow-sm gap-2  p-2   "
                                      key={index}
                                      onClick={() =>
                                        manageNotification(msg?._id)
                                      }
                                    >
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <Icon
                                            icon="ic:twotone-message"
                                            width="30"
                                            color={colors?.darkblue}
                                          />
                                        </div>
                                        <div className="ms-2 ">
                                          <p
                                            className={`fw-bold ${
                                              mode === "dark"
                                                ? "text-white"
                                                : "text-black "
                                            }`}
                                          >
                                            {currentLanguage === "fr"
                                              ? msg?.subject_fr
                                              : msg?.subject_en}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-end  ">
                                        {moment(msg?.created_at)?.calendar()}
                                      </div>
                                    </div>
                                  );
                                })}
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/** -------Search bar section-- */}
              {selector?.authUserData?.userType == 2 && (
                <div className="searchbar w-100  ms-lg-5 rounded-4  d-flex flex-row justify-content-center align-items-center ">
                  {" "}
                  <div className="w-100 p-md-2 p-1  d-flex flex-row align-items-center">
                    <Icon
                      icon="zondicons:education"
                      width={27}
                      color={colors.orange}
                    />
                    <Select
                      options={optionsGrade}
                      className="selectbox ms-2 w-100"
                      placeholder={t("selectYourLevel")}
                      styles={customStyles(mode)}
                      onChange={(e) => handleGradeOption(e?.value, "level_id")}
                    />
                  </div>
                  <div className="w-100 p-2 d-flex flex-row align-items-center ">
                    {" "}
                    <Icon
                      icon="emojione-monotone:books"
                      width={28}
                      color={colors.orange}
                    />
                    <Select
                      options={optionSubject}
                      className="selectbox ms-2 w-100 border-0 "
                      placeholder={t("selectYourSubject")}
                      styles={customStyles(mode)}
                      onChange={(e) =>
                        handleGradeOption(e?.value, "subject_id")
                      }
                    />
                  </div>
                  <div className="w-75 text-center d-none d-md-inline  ">
                    {" "}
                    {/**----search button for large screen */}
                    <button
                      className="btn-find border-0 p-md-1 p-1 px-3  font-weight-bold text-white text-center  me-1 "
                      onClick={() => {
                        if (
                          searchData?.level_id !== "" &&
                          searchData?.subject_id !== ""
                        ) {
                          history.push({
                            pathname: "/searchTeacher",
                            state: searchData,
                          });
                        } else {
                          notistack.warning(t("sreachWarning"));
                        }
                      }}
                    >
                      {t("find_a_teacher")}
                    </button>
                  </div>
                  <div className="w-25 text-center d-md-none">
                    {" "}
                    {/**----search button for small screen */}
                    <button
                      className="btn-find-small border-0  p-2 text-white text-center float-end me-1 "
                      onClick={() =>
                        history.push({
                          pathname: "/searchTeacher",
                          state: searchData,
                        })
                      }
                    >
                      <Icon icon="bi:search" width={20} />
                    </button>
                  </div>
                </div>
              )}
              {/* -----------------Show wallet blanace section-------- */}
              {selector?.authUserData?.userType == 2 && (
                <div
                  className="balance-blk d-lg-none"
                  onClick={() => {
                    setModal(1);
                    setIsOpen(true);
                  }}
                >
                  {t('balance')} :
                  <span className="fw-bold ms-1  ">
                    {selector?.authUserData?.wallet_amount.toFixed(2)} €
                  </span>
                </div>
              )}
            </div>

            <div className="col-lg-3 col-12  p-1  d-none d-lg-inline align-self-center  ">
              <div className="float-end w-100 d-flex justify-content-around align-items-center position-relative  ">
             
                <div className="right-nav rounded   ">
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      {notification?.totalCount > 0 && (
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {notification?.totalCount}
                        </span>
                      )}
                      <Icon
                        icon="zondicons:notification"
                        width={25}
                        color={mode === "dark" ? "white" : "black"}
                        onClick={manageNotificationBox}
                      />
                      {showNotification && (
                        <ul
                          className="dropdown list-unstyled mt-2"
                          style={
                            mode === "dark"
                              ? { backgroundColor: "#000000",zIndex:9999 }
                              : { backgroundColor: "#fff",zIndex:9999 }
                          }
                        >
                          <li className="nav-item noti-center">
                            <div className="d-flex justify-content-between align-items-center ">
                              <h6 className="p-3 ">Notification</h6>
                              <span
                                className="px-2 text-danger fw-bold "
                                onClick={() => {
                                  clearNotification();
                                  manageNotificationBox();
                                }}
                              >
                                {t("clear_all")}{" "}
                              </span>
                            </div>
                            {notification?.data?.map((msg, index) => {
                              return (
                                <div
                                  className=" my-2 rounded shadow-sm gap-2  p-2   "
                                  key={index}
                                  onClick={() => manageNotification(msg?._id)}
                                >
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <Icon
                                        icon="ic:twotone-message"
                                        width="30"
                                        color={colors?.darkblue}
                                      />
                                    </div>
                                    <div className="ms-2 ">
                                      <p
                                        className={`fw-bold ${
                                          mode === "dark"
                                            ? "text-white"
                                            : "text-black "
                                        }`}
                                      >
                                        {currentLanguage === "fr"
                                          ? msg?.subject_fr
                                          : msg?.subject_en}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end  ">
                                    {moment(msg?.created_at)?.calendar()}
                                  </div>
                                </div>
                              );
                            })}
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
               
                <CustomProvider theme={mode === "dark" ?'dark':'light'}>
                <Dropdown
                  placement="bottomEnd"
                  menuStyle={customStyles2(mode)}
                  renderToggle={(props) => (
                    <Avatar
                      circle
                      {...props}
                      src={authUserData?.image}
                    />
                  )}
                >
                  <Dropdown.Item panel style={{ padding: 10}}>
                    <p >{t('signAs')}:</p>
                    <p className="text-center "><strong >{authUserData?.first_name}</strong></p>
                  </Dropdown.Item>
                  <Dropdown.Separator />
                  <Dropdown.Item onClick={() => handleChangeLanguage()}>
                    {" "}
                    <div
                      className=" d-flex justify-content-between align-items-center user-select-none gap-1  "
                      
                      style={{ cursor: "pointer" }}
                    >
                    {t('language')}:  {currentLanguage === "en" ? (
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/en.webp"}
                          className="lang-img"
                          loading="lazy"
                          height={'100%'} width={'100%'}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/fr.webp"}
                          className="lang-img"
                          loading="lazy"
                          height={'100%'} width={'100%'}
                        />
                      )}
                    </div>{" "}
                  </Dropdown.Item>
                  <Dropdown.Item  onClick={() =>
                        setMode(mode === "dark" ? "light" : "dark")
                      }>
                    <div
                      className="dark-btn  justify-content-between gap-1 align-items-center d-none d-lg-flex  "
                     
                      style={{ cursor: "pointer" }}
                    >
                    {t('theme')}:  {mode !== "dark" ? (
                        <Icon
                          icon="ph:moon-duotone"
                          color="#000000"
                          width={"25"}
                        />
                      ) : (
                        <Icon
                          icon="ph:sun-duotone"
                          color="yellow"
                          width={"25"}
                        />
                      )}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item  onClick={() => {
                        setModal(2);
                        setIsOpen(true);
                      }}>
                    <div
                      className="dark-btn  justify-content-between align-items-center d-none d-lg-flex gap-1 word-break  "
                     
                      style={{ cursor: "pointer" }}
                    >
                      {t('logout')}:
                      <Icon icon="mdi:sign-out" color="#cc0001" width={"25"} />
                    </div>
                  </Dropdown.Item>
                </Dropdown>
                </CustomProvider>
                {selector?.authUserData?.userType == 2 && (
                  <div
                    className="right-nav rounded user-select-none d-flex flex-column "
                    onClick={() => {
                      setModal(1);
                      setIsOpen(true);
                    }}
                  >
                    {t('balance')} :
                    <span className="fw-bold ms-1  ">
                      {selector?.authUserData?.wallet_amount.toFixed(2)} €
                    </span>
                  </div>
                )}
              
              </div>
            </div>
          </div>
        </div>
       

        {/** -----------Nav block with menu items---- */}
        <div className="partTwo d-none d-lg-flex">
          {" "}
          <div className="container ">
            <div
              className="w-100 m-auto d-flex justify-content-around"
              style={
                selector?.authUserData?.first_time_online
                  ? { padding: "15px" }
                  : { padding: "0px" }
              }
            >
              {!selector?.authUserData?.first_time_online && (
                <>
                  <Link
                    className={`nav-btn w-100 py-2 text-white text-center ${
                      props?.active === 1 ? "active" : ""
                    }`}
                    // onClick={() => setActive(1)}
                    to={process.env.PUBLIC_URL + "/dashboard"}
                  >
                    {t("dashboard")}
                  </Link>
                  {selector?.authUserData?.userType == 2 ? (
                    <Link
                      className={`nav-btn w-100 py-2 text-white text-center ${
                        props?.active === 2 ? "active" : ""
                      }`}
                      // onClick={() => setActive(2)}
                      to={process.env.PUBLIC_URL + "/myclasses"}
                    >
                      {t("myClasses")}
                    </Link>
                  ) : (
                    <Link
                      className={`nav-btn w-100 py-2 text-white text-center ${
                        props?.active === 2 ? "active" : ""
                      }`}
                      // onClick={() => setActive(2)}
                      to={process.env.PUBLIC_URL + "/myclasses"}
                    >
                      {t("myClassesRequest")}
                    </Link>
                  )}
                  {selector?.authUserData?.userType == 1 ? (
                    <Link
                      className={`nav-btn w-100 py-2 text-white text-center ${
                        props?.active === 3 ? "active" : ""
                      }`}
                      to={process.env.PUBLIC_URL + "/myadvert"}
                      // onClick={() => setActive(3)}
                    >
                      {t("myAdvert")}
                    </Link>
                  ) : (
                    <Link
                      className={`nav-btn w-100 py-2 text-white text-center ${
                        props?.active === 3 ? "active" : ""
                      }`}
                      to={process.env.PUBLIC_URL + "/favourites"}
                      // onClick={() => setActive(3)}
                    >
                      {t("favourites")}
                    </Link>
                  )}
                  <Link
                    className={`nav-btn w-100 py-2 text-white text-center ${
                      props?.active === 4 ? "active" : ""
                    }`}
                    to={process.env.PUBLIC_URL + "/myAccount"}
                    // onClick={() => setActive(4)}
                  >
                    {t("myAccount")}
                  </Link>

                  <Link
                    className={`nav-btn w-100 py-2 text-white text-center ${
                      props?.active === 5 ? "active" : ""
                    }`}
                    // onClick={() => setActive(2)}
                    to={process.env.PUBLIC_URL + "/MyPayments"}
                  >
                    {t("myPayments")}
                  </Link>
                  {selector?.authUserData?.userType == 1 && (
                    <>
                      <Link
                        className={`nav-btn w-100 py-2 text-white text-center ${
                          props?.active === 6 ? "active" : ""
                        }`}
                        // onClick={() => setActive(2)}
                        to={process.env.PUBLIC_URL + "/Subscription"}
                      >
                        {t("UpgradeMembership")}
                      </Link>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {modal === 1 && (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
          <Modal.Header>
            <Modal.Title>{t(`youWallet`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="fw-bold text-center ">{t(`yourWalletBalance`)}</p>
            <p
              className={`fw-bold text-center fs-3 my-3  ${
                selector?.authUserData?.wallet_amount && "text-success"
              }`}
            >
              {selector?.authUserData?.wallet_amount?.toFixed(2)} €
            </p>
            <p className="mt-3 ">{t(`balanceModelMessage`)}</p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between ">
            <Button
              variant="none"
              onClick={() => {
                setModal(3);
              }}
              style={{ backgroundColor: colors?.orange, fontWeight: 500 }}
              className="text-white  "
            >
              {t("addMoneyInWallet")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {modal === 2 && (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} style={{zIndex:9999}}>
          <Modal.Header>
            <Modal.Title>{t("logout")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>{t("logoutPara")}</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              {t("cancel")}
            </Button>
            <Button variant="danger" onClick={logout}>
              {t("logout")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {modal === 3 && (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
          <Modal.Header>
            <Modal.Title>{t("rechargeWallet")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>{t("rechargePara")} :</h6>
            <div className="col-3 m-auto mt-3 d-flex align-items-center gap-1 fw-bold fs-5    ">
              <input
                type="text"
                name="amount"
                className="form-control fw-bold text-success fs-5 "
                value={rechargeAmt?.amount}
                onChange={(e) => manageRecharge(e)}
              />
              €
            </div>
            {rechargeAmt?.amount > 0 && (
              <Button
                variant="success"
                className="w-100 mt-3"
                onClick={rechargeWallet}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden" role="status">
                      Loading...
                    </span>
                  </>
                ) : (
                  t("rechargeWallet")
                )}
              </Button>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setIsOpen(false)}>
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Styles>
  );
}

export default DashboardNavbar;
