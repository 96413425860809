
const initalState = {
  intialValue: "",
  authUserData: null,
  language:null,
  subject:null,
  grade:null,
  socket:null,
  error: null,
  notification:null,
  access:false,
  isLogin:false,
};
const intialDataReducer = (state = initalState, action) => {
  switch (action.type) {
    case "GETLANGUAGE_SUCCESS":
      return{
        ...state,
        language:action.payload,
      }
    case "GETLANGUAGE_FAILURE":
      return{
        ...state,
        language:action.payload,
      }
      case "GETGRADE_SUCCESS":
        return{
          ...state,
          grade:action.payload,

        }
      case "GETGRADE_FAILURE":
        return{
          ...state,
          grade:action.payload,
        }
      case "GETSUBJECT_SUCCESS":
        return{
          ...state,
          subject:action.payload,

        }
      case "GETSUBJECT_FAILURE":
        return{
          ...state,
          subject:action.payload,
        }
      case "GETAUTHUSER_SUCCESS":
        return {
          ...state,
          authUserData: action.payload,
          error: null,
        };
  
      case "GETAUTHUSER_FAILURE":
        return {
          ...state,
          error: action.payload,
        };
      case "GET_SOCKET":
        return {
          ...state,
          socket: action.payload,
        };
      case "SET_NOTIFICATION":
        return {
          ...state,
          notification: action.payload,
        };
      case "SET_ACCESS":
        return {
          ...state,
          access: action.payload,
        };
      case "SET_ISLOGIN":
        return {
          ...state,
          isLogin: action.payload,
        };
    default:
      return state;
      
  }
};

export default intialDataReducer;
