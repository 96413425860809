import { io } from "socket.io-client";
import { auth, subjectGrade } from "../puglins/Utils/models";
import notistack from "../puglins/notistack";
import noNetworkAction from "../puglins/Utils/errorManage";
import {useHistory } from "react-router-dom";


export const getAuthUser = (history) => {
  return (dispatch) => {
    auth
      .getAuthUserData()
      .then((res) => {
        dispatch(getAuthUserSuccess(res?.data));
      })
      .catch((error) => {
        error?.message==='Network Error'&&noNetworkAction(history)
        dispatch(getAuthUserFailure(error));
      });
  };
};
export const setAccess = (data) => {
  return {
    type: "SET_ACCESS",
    payload: data,
  };
};
export const isLogin = (data) => {
  return {
    type: "SET_ISLOGIN",
    payload: data,
  };
};
export const getNotificationData = (history) => {
  return (dispatch) => {
    auth
      .getNotification()
      .then((res) => {
        dispatch({ type: "SET_NOTIFICATION", payload: res } || []);
      })
      .catch((error) => {
        error?.message==='Network Error'&&noNetworkAction(history)
        notistack.error("Error while getting notification");
      });
  };
};
export const getLanguages = (history) => {
  return (dispatch) => {
    subjectGrade
      .getLangauge()
      .then((response) => {
        dispatch(getLanguageSuccess(response?.data));
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        dispatch(getLanguageFailure(error));
        console.log("Error while Getting Language", error);
      });
  };
};
// ------To get grade Intially and store it in redux
export const getGrade = (history) => {
  return (dispatch) => {
    subjectGrade
      .getGrade()
      .then((res) => {
        dispatch(getGradeSuccess(res?.data));
      })
      .catch((err) => {
        err?.message === "Network Error" && noNetworkAction(history);
        dispatch(getGradeFailure(err));
        console.log("Error while fetching grades", err);
      });
  };
};
// ------To get subject Intially and store it in redux
export const getSubjects = (history) => {
  return (dispatch) => {
    subjectGrade
      .getSubjects()
      .then((res) => {
        dispatch(getSubjectSuccess(res?.data));
      })
      .catch((err) => {
        err?.message === "Network Error" && noNetworkAction(history);
        dispatch(getSubjectFailure(err));
        console.log("Error while fetching subject", err);
      });
  };
};
export const getAuthUserSuccess = (userData) => {
  return {
    type: "GETAUTHUSER_SUCCESS",
    payload: userData,
  };
};

export const getAuthUserFailure = (error) => {
  return {
    type: "GETAUTHUSER_FAILURE",
    payload: error,
  };
};

export const getLanguageSuccess = (data) => {
  return {
    type: "GETLANGUAGE_SUCCESS",
    payload: data,
  };
};

export const getLanguageFailure = (error) => {
  return {
    type: "GETLANGUAGE_FAILURE",
    payload: error,
  };
};

export const getGradeSuccess = (data) => {
  return {
    type: "GETGRADE_SUCCESS",
    payload: data,
  };
};

export const getGradeFailure = (error) => {
  return {
    type: "GETGRADE_FAILURE",
    payload: error,
  };
};
export const getSubjectSuccess = (data) => {
  return {
    type: "GETSUBJECT_SUCCESS",
    payload: data,
  };
};

export const getSubjectFailure = (error) => {
  return {
    type: "GETSUBJECT_FAILURE",
    payload: error,
  };
};
export const getSocket = () => {
  const socket = io(process.env.REACT_APP_SOCKET_IO_URL,{
    path: "/socket.io",
    transports: [ "websocket" ],
    secure: true,

  });
  return {
    type: "GET_SOCKET",
    payload: socket,
  };
};
